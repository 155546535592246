@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

*{
  font-family: "Nunito", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
  .no-scrollbar::-webkit-scrollbar{
    display: none;
  }
  .no-scrollbar{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
